import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import Footer from './Footer'

const LayoutWrapper = styled.div`
    ${tw`max-w-screen overflow-x-hidden h-auto m-0`};
`
const Layout = (props) => (

    <LayoutWrapper className={props.className}>
        {props.children}

        <Footer/>
    </LayoutWrapper>
    
);

export default Layout;