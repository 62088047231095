import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import {css} from 'emotion';
import { Spring, animated, interpolate, Transition} from 'react-spring/renderprops';
import Row from './Row';
import {Link} from 'gatsby';
import Geocode from "react-geocode";
import axios from 'axios';


Geocode.setApiKey("AIzaSyAEarG8tKd2cFXWtDkAfBOrymFUCfjuFok");


const Wrapper = styled.div`
    ${tw`fixed w-screen bg-white overflow-hidden`};
    z-index: 900;
    border-radius: 0 0 1.5rem 1.5rem;
    box-shadow: 0 10px 20px rgba(30,30,50,0.2);
    height: 40rem;
    @media(min-width: 992px){
        height: 30rem;
    }
`
const InnerWrapper = styled.div`
    ${tw`flex flex-wrap items-start relative h-full w-full pt-16 scrolling-touch px-2 lg:px-10 max-w-row mx-auto`};
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
`

const AnimatedWrapper = animated(Wrapper);

const SearchInput = styled.input`
    ${tw`w-full py-4 text-2xl font-medium px-2 border-none outline-none my-4`};
    border-bottom: 1px solid grey;
`
const SearchResults = styled.ul`
    ${tw`list-reset m-0 w-full`};
    li{
        my-4
    }
`

const Temperature = styled.div`
    ${tw`flex w-full items-center`}
    img{
        ${tw`inline-flex w-18`};

    }
    p{
        ${tw`text-5xl font-bold tracking-tight mt-4 ml-2`};
    }
`
const CityName = styled.div`
    ${tw`font-medium text-grey-dark text-3xl uppercase`};
`

class Menu extends React.Component{
    constructor(props){
        super(props);

        this.state={
            text: '',
            searchResults: [],
            noResultsFound: false
        }
        this.useGeolocation = this.useGeolocation.bind(this);
        this.getWeatherInfo = this.getWeatherInfo.bind(this);
    }

    componentWillMount(){
        //this.useGeolocation();
    }

    componentDidUpdate(prevProps, prevState){
        //debugger
        if(this.props.searchResults != prevProps.searchResults){
            this.setState({ searchResults: this.props.searchResults });
        }
        if(this.props.noResultsFound && this.props.noResultsFound != prevProps.noResultsFound){
            this.setState({ noResultsFound: true });
        }
        if (!this.props.noResultsFound  && this.props.searchResults.length > 0 && this.state.noResultsFound) {
            this.setState({ noResultsFound: false });
        }    
    }


    useGeolocation() {
        //debugger
        let flag, city, coords;
        let self = this;
        let options = {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0
        };
        //eslint-disable-next-line
        if (typeof navigator != `undefined`) {
            const geolocation = navigator.geolocation;
            geolocation.getCurrentPosition(success, error, options);
            function success(pos) {
                //debugger
                let crd = pos.coords;
                coords = crd;
                let latitude = coords.latitude;
                let longitude = coords.longitude;
                Geocode.fromLatLng(latitude, longitude)
                    .then(response => {
                        //console.log(response);
                        let result = response.results[0];
                        // self.setState({ pickupAddress: result.formatted_address });
                        // self.props.dispatch(updatePickupLocation({ name: result.formatted_address, locLat: result.geometry.location.lat, locLng: result.geometry.location.lng }));
                        let types = ['locality', 'administrative_area_level_2', 'administrative_area_level_1'];
                        for (let i = 0; i < 3; i++) {
                            for (let j = 0; j < result.address_components.length; j++) {
                                if (result.address_components[j].types[0] == types[i]) {
                                    city = result.address_components[j].long_name;
                                    flag = 1;
                                    break;
                                }
                            }
                            if (flag == 1) {
                                if (city == 'Secunderabad') {
                                    city = 'Hyderabad';
                                }
                                if (city == "Bangalore" || city == "Bengaluru Rural" || city == "Bengaluru Urban" || city == "Bangalore Rural" || city == "Bangalore Urban") {
                                    city = "Bengaluru";
                                }
                                // self.props.dispatch(fetchLocationByLatLong(city));
                                self.setState({ city });
                                self.getWeatherInfo(city);
                                break;
                            }
                        }
                        // const address = response.results[0].formatted_address;
                        // console.log(address);
                    },
                        error => {
                            console.error(error);
                        }
                    );
            }
            function error(err) {
                console.warn(`ERROR(${err.code}): ${err.message}`);
                if (err.code == 1) {
                    // alert("Please Enable Location Service to Locate You or Enter Pick Up Location Manually.");
                    // self.props.dispatch(appActions.geoLocationServiceFail());
                }
                else if (err.code == 3) {
                    // alert('Unable to Locate You at this Moment. Please Try Again or Enter Your Location Manually.');
                    // self.props.dispatch(appActions.geoLocationServiceFail());
                }
            }
        }
    }

    getWeatherInfo(city) {
        let self = this;
        let apiKey = "a500548aa2b4dad0c01079306b5e5f7b";
        let url = `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${apiKey}`
        axios.get(url)
            .then(res => {
                if (res.status == 200) {
                    self.setState({ temp: parseInt(res.data.main.temp - 273) });
                }
            })
    }

    render(){
        return(
            <Spring native
        from={{y: -100, shadowAlpha: this.props.active ? 0 : 0.4 }}
        to={{y: this.props.active ? 0 : -120}}>
        {
            values =>
            <AnimatedWrapper style={{
                transform: values.y.interpolate(y => `translate3d(0,${y}vh, 0)`),
                }}>

                <InnerWrapper>

                    {this.props.showSearch 
                        ? (
                            <Row className={css`${tw``}`}>
                                <SearchInput 
                                    placeholder="Search for Articles, categories etc." 
                                    type="text" 
                                    value={this.state.text}
                                    onChange={(e) => this.setState({ text: e.target.value }, function(){
                                        if(this.state.text == '' && this.state.searchResults.length >= 0){
                                            //debugger
                                            this.setState({ searchResults: [], noResultsFound: false });
                                        }
                                    })}
                                    onKeyPress={(e) => this.props.handleKeyPress(e, this.state.text)}
                                />
                                <p className={css`${tw`text-sm italic font-light text-grey-light w-full mb-10`}`}>Press enter to search</p>
                                {!this.state.noResultsFound ? <SearchResults>
                                    {this.state.searchResults.map((result, i) => 
                                        <li>
                                            <Link to={result.uid}
                                                className={css`${tw`text-black no-underline opacity-60 hover:opacity-100 cursor-pointer`}`}>
                                                {result.data.title[0].text}
                                            </Link>
                                        </li>
                                    )}
                                </SearchResults>: <p>Sorry! No results found.</p>}
                            </Row>
                        ) 
                        : (
                            <div className={css`${tw`h-full items-center w-full flex flex-wrap`}`}>
                                <Row justifyBetween>

                                    <div className={css`${tw`flex flex-wrap items-center w-full my-4 lg:w-1/2`}`}>

                                        <CityName>{this.state.city}</CityName>
                                        
                                    </div>
                                    <div className={css`${tw`flex my-4 lg:justify-end w-full lg:w-1/2 lg:text-right`}`}>
                                        <ul className={css`${tw`list-reset m-0`}`}>
                                            <li className={css`${tw`my-4`}`}>
                                                <Link to='/' className={css`${tw`no-underline text-grey-darker opacity-80 hover:opacity-100 cursor-pointer py-4`}`}>
                                                    Home
                                                </Link>
                                            </li>
                                            <li className={css`${tw`my-4`}`}>
                                                <Link to='/terms/' className={css`${tw`no-underline text-grey-darker opacity-80 hover:opacity-100 cursor-pointer py-4`}`}>
                                                    Terms &amp; Conditions
                                                </Link>
                                            </li>

                                        </ul>
                                    </div>
                                </Row>
                            </div>
                        )
                    }

                </InnerWrapper>

            </AnimatedWrapper>

        }
    </Spring>
        )
    }
    
};

export default Menu;