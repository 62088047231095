import React from 'react';
import tw from 'tailwind.macro';


const SearchIcon = (props) => (

    <svg onClick={props.onClick} 
        width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.657 14.657L19 19l-4.343-4.343A8 8 0 1 1 3.343 3.343a8 8 0 0 1 11.314 11.314z" 
            stroke={props.dark ? "#19191A" : '#F2F4F7'} 
            strokeWidth="2" 
            fill="none" 
            fillRule="evenodd" 
            strokeLinecap="round" 
            strokeLinejoin="round" />
    </svg>
);

export default SearchIcon;