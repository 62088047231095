import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`

`
const Logo = (props) => (
    <SVG height='34' width='75' xmlns="http://www.w3.org/2000/svg">
        <g fill={props.dark ? '#222222' : "#FFF"} fillRule="evenodd">
            <path d="M31.98 26.075c-.005.51-.146.99-.388 1.41a2.914 2.914 0 0 1-2.536 1.465c-1.6 0-2.904-1.29-2.919-2.875v-.03c0-.53.146-1.03.402-1.46a2.9 2.9 0 0 1 2.517-1.44c.564 0 1.087.155 1.53.43a2.885 2.885 0 0 1 1.394 2.47v.03zm-21.405-1.73l-.011 1.7v.03a2.913 2.913 0 0 1-2.92 2.875 2.928 2.928 0 0 1-2.536-1.465c-.236-.42-.377-.9-.382-1.41v-.03a2.899 2.899 0 0 1 1.53-2.55L10.6 21.01l-.025 3.335zm22.83-4.55L26.952 7.58l-1.635-3.095-.005-.015A7.634 7.634 0 0 0 18.965.015C18.758.01 18.557 0 18.35 0c-.232 0-.463.01-.695.015-3.906.34-6.971 3.595-6.971 7.565l-.05 7.98-6.594 3.78-.13.08A7.572 7.572 0 0 0 0 26.045v.03a7.506 7.506 0 0 0 .921 3.585l.095.17c.03.045.061.105.091.155a7.648 7.648 0 0 0 6.538 3.655c4.213 0 7.63-3.39 7.646-7.565v-.03l.03-4.42.02-3.34 3.01-1.725 2.903-1.66 2.237-1.324c-.746-1.367-1.49-2.734-2.237-4.101V9.47l-2.903 1.67-2.975 1.7.035-5.26c0-1.6 1.309-2.9 2.92-2.9h.02a2.905 2.905 0 0 1 2.496 1.43l.09.16.317.57.414.74.699 1.255 1.943 3.475.352.64 3.126 5.61a7.61 7.61 0 0 0-5.345 3.675 7.513 7.513 0 0 0-1.032 3.81v.03c.014 4.175 3.432 7.565 7.645 7.565a7.666 7.666 0 0 0 6.502-3.59c.016-.025.026-.05.04-.065.031-.05.062-.11.086-.155.006-.015.01-.02.016-.025.01-.005.01-.02.015-.025.035-.05.065-.11.1-.17a7.54 7.54 0 0 0 .891-3.535v-.03l-3.301-6.25zM54.662 31.541l-4.79.001.133-7.527-5.868-.133-.197 7.658-4.612.005.369-21.023.003-.247 4.786.165-.192 7.55h5.818l.13-7.523 4.79.098-.37 20.976zm14.298-.233l-11.297-.001.164-20.974-.207-.003 5.202-.001-.151 15.31h6.44l-.151 5.67z" />
        </g>
    </SVG>

);

export default Logo;