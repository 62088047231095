import React from 'react';
import PropTypes from 'prop-types';

export default class GoogleAd extends React.Component {
  render() {
    return (
      <ins className={`${this.props.className} adsbygoogle`}
        style={this.props.style} 
        data-ad-client={'ca-pub-5630104718741028'} 
        data-ad-slot={this.props.slot}
        data-ad-layout={this.props.layout}
        data-ad-format={this.props.format}
        data-adtest='on'
        data-full-width-responsive={this.props.responsive}></ins>
    );
  }
};

GoogleAd.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line
  client: PropTypes.string.isRequired,
  slot: PropTypes.string.isRequired,
  layout: PropTypes.string,
  format: PropTypes.string,
  responsive: PropTypes.string
};

GoogleAd.defaultProps = {
  className: '',
  style: {display: 'block'}, 
  format: 'auto',
  layout: '',
  responsive: 'false'
};


{/* <script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
        <script>
            (adsbygoogle = window.adsbygoogle || []).push({
                google_ad_client: "ca-pub-8988417827627902",
            enable_page_level_ads: true
        }); 
        </script>*/}