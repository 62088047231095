import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import {Spring, animated, interpolate} from 'react-spring/renderprops';


const SVG = styled.svg`
    ${tw`hover:bg-grey-light`};
    &:hover{
        transform: scale(0.9);
        background: rgba(200,200,220,0.1);
        cursor: pointer;
        transition: 0.25s ease-in-out;
    }
`

const Hamburger =(props) =>(
        <SVG
            onClick={props.onClick} width="20" height="14"
            className={props.className}
            style={props.style}

            xmlns="http://www.w3.org/2000/svg">
            <g stroke={props.dark ? "#19191A" : "#F2F4F7"} strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <path d="M1 7h18M1 1h18M1 13h18" />
            </g>
        </SVG>
);


export default Hamburger;