import React from 'react';
import styled from 'styled-components';
import {css} from 'emotion';
import tw from 'tailwind.macro';
import Row from './Row';
import Logo from './Logo';
import SearchIcon from './SearchIcon';
import Hamburger from './Hamburger';
import {Spring, Transition, animated, interpolate} from 'react-spring/renderprops';
import Close from './Close';
import { Link } from 'gatsby';
import Adsense from 'react-adsense';

const Wrapper = styled.div`
    ${tw`fixed pin-t pin-x w-screen py-4 text-white z-999 mt-8`};
    // box-shadow: 0 5px 20px rgba(20,20,40,0.2);
 
`
const AnimatedWrapper = animated(Wrapper);

const SearchBar = styled.div`
    ${tw`relative overflow-hidden px-4 py-2 flex items-center rounded-full`};
    background: rgba(200,200,200,0.15);
`
const SearchInput = styled.input`
    ${tw`h-full text-grey appearance-none shadow-none border-none outline-none bg-transparent mr-6`}
    width: 8rem;

`

const LogoTitle = styled.p`
    ${tw`hidden lg:inline-flex font-thin text-grey-darker text-xl ml-2 pl-2 mt-2 opacity-80`};
    border-left: 2px solid rgba(220,220,220,0.2);
`

const NavBar = (props) => (
    <Spring 
        native
        from={{bgAlpha: 0, mt: 2}}
        to={{bgAlpha: props.dark? 1 : 0, mt: props.dark ? 2 : 0}}>
        {values =>
            <AnimatedWrapper style={{
                backgroundColor: values.bgAlpha.interpolate(bgAlpha => `rgba(255,255,255,${bgAlpha})` ),
                transform: values.mt.interpolate(mt => `translate3d(0, -${mt}rem, 0)`)
            }}>
                <Row className={css`${tw`px-6 lg:px-8`}`} justifyBetween>
                    <div className={css`${tw`relative z-0 flex items-center justify-start w-1/4`}`}>

                        <Link to="/" className={css`${tw`absolute h-full w-full pin-t pin-l z-10`}`}></Link>
                            <Logo dark={props.dark} />
                            {/*<LogoTitle>andhraheadlines.com</LogoTitle>*/}
                    </div>

                    <div className={css`${tw`inline-flex items-center justify-end w-1/4`}`}>
                        {!props.showClose && <SearchIcon onClick={props.onSearchClick} dark={props.dark} className={css`${tw``}`} />}
                        {!props.showClose && <Hamburger onClick={props.menuClick} dark={props.dark} className={css`${tw`mx-4`}`} />}
                        {props.showClose && <Close onClick={props.onCloseClick}/>}
                    </div>

                </Row>
            </AnimatedWrapper>
        }
    </Spring>
    
);

export default NavBar;