import React from 'react';
import tw from 'tailwind.macro';
import styled from 'styled-components';
import { css } from 'emotion';
import Row from './Row'
import { Link } from 'gatsby';

import { Transition, animated, interpolate } from 'react-spring/renderprops';

const Wrapper = styled.div`
  
    ${tw`w-full 
        flex 
        flex-wrap 
        items-end 
        bg-white 
        rounded-lg 
        relative 
        z-0 
        overflow-hidden 
        text-black 
        my-4
        mx-auto 
        lg:mx-0
        cursor-pointer
        hover:shadow-lg`};
    transition: all 0.25s ease-in-out;  
    
    ${props => (props.feed && !props.related) && `
        @media (min-width: 992px){
            width: 40rem;
            height: 14rem;
        }
        width: 40rem;
        height: 22rem;
        margin: 1rem !important;
    `}

`
const StyledImage = styled.div`
    ${tw`h-full pin-t pin-l`}; 
    width: 18rem;
    z-index: -2;
    @media (max-width: 992px){
        position: absolute;
        width: 100%;
        mask-image: linear-gradient(black 30%, transparent 80%); 
    -webkit-mask-image: linear-gradient(black 30%, transparent 80%); 
    }
    ${props => `background-image: url(${props.image})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
`
const Title = styled.h2`
    ${tw`text-base w-full`};
`
const CategoryLabel = styled.p`
    ${tw`absolute z-100 text-xs font-semibold px-4 py-1 rounded-full  uppercase`};
    top: 8%;
    color: #1E1E1E;
    opacity: 0.4;
    letter-spacing: 1.66px;
    text-align: center;
    background: rgba(228,233,249,1);
    @media (min-width: 992px){
        left: 5%;
    }
    @media (max-width: 992px){
        right: 5%;
    }
`
const Content = styled.p`
    ${tw`text-base w-full`};
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    opacity: 0.77;
    font-family: HelveticaNeue;
    font-size: 14px;
    color: #2D2D2E;
    letter-spacing: 0.46px;
    line-height: 1.6;
    @media (max-width: 992px){
        display: none;
    }
`
const ContentSection = styled.div`
    ${tw`p-4`};     
    @media (min-width: 992px){
        width: 22rem;
        height: 100%;
    }
`

const AnimatedWrapper = animated(Wrapper);
const HorizontalCard = (props) => (
    <Wrapper {...props}>
        <CategoryLabel>
            {props.category}
        </CategoryLabel>
        <StyledImage image={props.img} />
        <ContentSection>
            <Row justifyBetween>
                <Title hero={props.hero}>{props.title}</Title>
                <Content>{props.content}</Content>
                <div className={css`${tw`flex items-center w-full h-6 mt-4`}`}>
                    <div className={css`${tw`inline-flex items-center uppercase tracking-wide`}`}>
                        <img className={css`${tw`h-3 mr-1`}`} src={require('../assets/icons/calendar.svg')} />
                        <p className={css`${tw`text-xxs opacity-70 text-grey-dark`}`}>{props.date}</p>
                    </div>
                    <div className={css`${tw`inline-flex items-center ml-4`}`}>
                        <img className={css`${tw`h-3 mr-1`}`} src={require('../assets/icons/clock.svg')} />
                        <p className={css`${tw`text-xxs opacity-70 text-grey-dark uppercase tracking-wide`}`}>{props.time}</p>
                    </div>
                </div>
            </Row>
        </ContentSection>
        <Link className={css`${tw`absolute z-50 h-full w-full pin-t pin-l no-underline`}`} to={props.uid + "/"}>
        </Link>
    </Wrapper>
);

export default HorizontalCard;