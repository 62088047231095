import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';

const StyledSvg = styled.svg`
    ${tw`cursor-pointer`}
    &:hover{
        transform: scale(0.9);
        transition: all 0.25s ease-in-out;
    }
`

const Close = (props) => (
    <StyledSvg 
        onClick={props.onClick} 
        style={props.style}
        width="38" height="38" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
            <circle fill="#19191A" cx="19" cy="19" r="19" />
            <path d="M18.098 18.958l-4.89-6.348a1 1 0 1 1 1.584-1.22l4.568 5.93 4.568-5.93a1 1 0 1 1 1.584 1.22l-4.89 6.348 4.89 6.349a1 1 0 0 1-1.584 1.22l-4.568-5.93-4.568 5.93a1 1 0 1 1-1.584-1.22l4.89-6.349z" fill="#EBEBEB" fill-rule="nonzero" />
        </g>
    </StyledSvg>
);

export default Close;