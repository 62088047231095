import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import Row from './Row';
import {css} from 'emotion';

const Wrapper = styled.div`
    ${tw`relative w-screen min-h-24 bg-black py-8 px-2 lg:px-8`};
    border-top: 2px solid rgba(10,20,30,0.1);
`

const Text = styled.p`
    ${tw`text-sm text-grey-dark`};
`

const Footer =(props)=> (

    <Wrapper>
        <Row justifyBetween>
            <div className={css`${tw`w-full lg:w-1/3`}`}>
                <Text>&copy; All Rights Reserved</Text>
            </div>
            {/* <div className={css`${tw`w-full lg:w-1/3 lg:text-right`}`}>
                <Text>AHL News Inc. Virginia, USA</Text>
            </div> */}
        </Row>
    </Wrapper>
);

export default Footer;