import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';

/* global tw */

const RowDiv = styled.div`
    ${tw`w-full h-auto flex flex-wrap max-w-row xl:px-0 px-4 mx-auto items-center`}
    ${props =>
        props.justifyBetween && tw`justify-between`
    }
    ${props =>
        props.justifyCenter && tw`justify-center`
    }
    ${props =>
        props.justifyEnd && tw`justify-end`
    }
    ${props =>
        props.justifyAround && tw`justify-around`
    }
`

const Row = (props) => (
    <RowDiv {...props} className={props.className}>
        {props.children}
    </RowDiv>
);

export default Row;